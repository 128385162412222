import React from "react";

const Footer = () => {
   return (
      <div className="footer">
         <div className="copyright">
            <p>
               Copyright © Designed &amp; Developed by{" "} <a href="https://www.infinitybits.pk/" target="_blank"> Infinity Bits </a>{" "} 2020
            </p>
         </div>
      </div>
   );
};

export default Footer;
